import React from "react";
import { useEffect } from "react";
import clsx from "clsx";
import "./carousel.scss";

const images = [
    "https://cdn1.goibibo.com/voy_mmt/t_fs/htl-imgs/200701131408174293-561589bc2f5711ee98f30a58a9feac02.jpg",
    "https://cdn1.goibibo.com/voy_mmt/t_fs/htl-imgs/200701131408174293-ccd1a8ee2eba11eeb6fb0a58a9feac02.jpg",
    "https://pix8.agoda.net/hotelImages/411055/-1/727ff49f5b957c96ecf70cfb5421e380.jpg?ce=0&s=1024x768",
    "https://res.cloudinary.com/dqsmrlhbk/image/upload/v1710573950/HotelsOnClik/Hotels/TreesNTigersSariska/WhatsApp_Image_2024-01-21_at_15.16.59_nuet7i.jpg",
    "https://pix8.agoda.net/hotelImages/107/10706850/10706850_21010218170093958483.jpg?ca=16&ce=1&s=1024x768",
    "https://images.unsplash.com/photo-1593693397690-362cb9666fc2?q=80&w=2938&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://pix8.agoda.net/hotelImages/1143724/-1/ea52987663c8592f04ed87e49e164fc4.jpg?ca=6&ce=1&s=1024x768",
  ];
  
  const numImages = images.length;
  
 const Carousel = () => {
    const [activeSlide, setActive] = React.useState(0);
    const [previousSlide, setPrevious] = React.useState(-1);
    const [isReverse, setReverse] = React.useState(false);


    useEffect(() => {
      const interval = setInterval(() => {
        const nextSlide = (activeSlide + 1) % numImages;
        handleSlideChange(nextSlide);
      }, 10000); // Change slide every 10 seconds
  
      return () => clearInterval(interval);
    }, );

    const handleSlideChange = (nextSlide) => {
      setPrevious(activeSlide);
      setActive(nextSlide);
      // setReverse(false);
    };
  
  
    const handleClick = (scale) => {
      let nextSlide = activeSlide + scale;
      nextSlide = ((nextSlide % numImages) + numImages) % numImages;
  
      setPrevious(activeSlide);
      setActive(nextSlide);
      setReverse(scale < 0);
    };
  
    const className = clsx("carousel", {
      reverse: isReverse
    });
  
    return (
      <div className={className}>
        {images.map((imageUrl, index) => (
          <CarouselSlide
            isActive={activeSlide === index}
            isPrevious={previousSlide === index}
            key={index}
            url={imageUrl}
          />
        ))}
  
        <div className="carousel-controls">
          <button className="previous" onClick={() => handleClick(-1)}>
            {"<"}
          </button>
  
          <button className="next" onClick={() => handleClick(1)}>
            {">"}
          </button>
        </div>
      </div>
    );
  };
  

  
  const CarouselSlide = props => {
    const { isActive, isPrevious, url } = props;
  
    const className = clsx("carousel-slide", {
      active: isActive,
      previous: isPrevious
    });
  
    return (
      <div className={className} style={{ backgroundImage: `url(${url})` }} />
    );
  };

export default Carousel;
  

