import React from "react";
import "./home.css";
import Carousel from "../../components/Carousel/carousel";
import Footer from "../../container/Footer/footer";
import Gallery from "../../container/Gallery/gallery";
import OurHotels from "../../container/OurHotels/OurHotels";
import ContactUsSection from "../../container/ContactUs/contactUs";
// import Navbar from "../../container/Navbar/navbar";
// import Image1 from "../../assets/Images/Image1.jpg";

const Home = () => (
  <>
  <div id="home" className="Home_page">
    <div className="Hero_Section">
      <Carousel />
    </div>
    <OurHotels />
    <Gallery />
    <ContactUsSection />
    <Footer />

  </div>
  </>
);

export default Home;
