import React from "react";
import classes from "./navbar.module.css";
import blackLogo from "../../assets/hotelsonclik-logos/Hotelsonclik-logos_black.png";
import whiteLogo from "../../assets/hotelsonclik-logos/Hotelsonclik-logos_white.png";
import Book from "../../components/Booking/bookNowModal";
import HotelsData from "../../constants/data";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [logoSrc, setLogoSrc] = useState(blackLogo);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleClick = () => {
    setClicked(!clicked);
    const navbar = document.querySelector(`.${classes.Navbar}`);
    document.body.style.overflow = clicked ? "auto" : "hidden";
    navbar.classList.toggle(classes.clickedNavbar);
    setLogoSrc(clicked ? blackLogo : whiteLogo);
  };

  const handleBookNowClick = () => {
    setShowBookingModal(!showBookingModal);
  };

  return (
    <nav className={`${classes.Navbar} ${scrolled ? classes.scrolled : ""} `}>
      <ul className={classes.Navbar__list}>
        <li className={classes.Navbar__item}>
          <div className={classes.Navbar__wrapper}>
            <button
              className={`${classes.menu__icon} ${
                clicked ? classes.clicked : ""
              }`}
              onClick={handleClick}
            >
              <span></span>
              <span></span>
              <span></span>
            </button>

            <div className={classes.Navbar__Menu}>
              <div className={classes.Menu__Content}>
                <div className={classes.Menu__LeftContent}>
                  <div className={classes.Menu__Heading}>
                    <h3>Hotels</h3>
                  </div>
                  <div className={classes.Menu__Lists}>
                    <ul className={classes.Menu__List}>
                      {HotelsData.map((hotel) => (
                        <li className={classes.Menu__ListItem} key={hotel.id}>
                          <Link
                            to={`/hotels/${hotel.link}`}
                            className={classes.Menu__ListLink}
                            onClick={handleClick}
                          >
                            {hotel.name}
                          </Link>
                        </li>
                      ))}
    
                    </ul>
                  </div>
                </div>
                <div className={classes.Menu__RightContent}>
                  <div className={classes.Menu__Image}>
                    <img
                      src="https://cdn1.goibibo.com/voy_mmt/t_fs/htl-imgs/200701131408174293-63097022377511eebf230a58a9feac02.jpg"
                      alt="logo"
                    />
                  </div>
                </div>
              </div>
              <hr className={classes.Menu__hr} />
              <div className={classes.Menu__Links}>
                <div
                  className={`${classes.Menu__link} ${classes.content__item}`}
                >
                  {" "}
                  <Link
                    to=""
                    className={`${classes.link} ${classes["link--dia"]}`}
                  >
                    Home
                  </Link>
                </div>
                <div
                  className={`${classes.Menu__link} ${classes.content__item}`}
                >
                  <Link
                    to=""
                    className={`${classes.link} ${classes["link--dia"]}`}
                  >
                    Offers
                  </Link>
                </div>
                <div
                  className={`${classes.Menu__link} ${classes.content__item}`}
                >
                  <Link
                    to=""
                    className={`${classes.link} ${classes["link--dia"]}`}
                  >
                    Contact
                  </Link>
                </div>
              </div>
            </div>
            <div className={classes.Navbar__link}>Menu</div>
          </div>
        </li>
        <li className={classes.Navbar__item}>
          <div className={classes.Navbar__logo}>
            <a href="/">
            <img src={logoSrc} alt="logo" />
            </a>
          </div>
        </li>
        <li className={classes.Navbar__item}>
          <div className={classes.Navbar__wrapper}>
            <div className={`${classes.Navbar__link} ${classes.content__item}`}>
              <Link
                to=""
                className={`${classes.offers} ${classes.link} ${classes["link--dia"]}`}
              >
                Offers
              </Link>
            </div>
            <div className={classes.Navbar__link}>
              <button
                className={classes.btn}
                onClick={handleBookNowClick}
              ></button>

              <div
                className={`${classes.bookingModal} ${
                  showBookingModal ? classes.bookingModalShow : ""
                }`}
              >
                <Book closeFn={handleBookNowClick} />
              </div>
            </div>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
