import React from "react";
import classes from "./ourHotels.module.css";
import Card1 from "../../components/Cards/Card1";
import HotelsData from "../../constants/data";
import { useState } from "react";
import BookNow from "../../components/Booking/bookNowModal";

const OurHotels = () => {
  const [showBookingModal, setShowBookingModal] = useState(false);
  const handleBookNowClick = () => {
    setShowBookingModal(!showBookingModal);
    console.log(showBookingModal);
  };
  return (
    <div className={classes.ourHotels}>
      <h2>Our Best Hotels</h2>
      <div className={classes.cardWrapper}>
      <div className={classes.cardContainer}>
        {HotelsData.map((hotel) => {
          return (
            <Card1
              key={hotel.id}
              cardImg={hotel.images}
              cardTitle={hotel.name}
              cardContent="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
              isBookNowClicked={handleBookNowClick}
            />
          );
        })}
      </div>
      </div>
      <div
          className={`${classes.bookingModal} ${
            showBookingModal ? classes.bookingModalShoow : ""
          }`}
        >
          <BookNow closeFn={handleBookNowClick} />
        </div>
    </div>
  );
};

export default OurHotels;
