import React from "react";
import styling from "./footer.module.css";
import { Link } from "react-router-dom";
import { FiFacebook, FiTwitter, FiInstagram } from "react-icons/fi";
import logo from "../../assets/hotelsonclik-logos/Hotelsonclik-logos_black.png";

const Footer = () => {
  const handleContactUsClick = () => {
    const contactSection = document.getElementById('cnt');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <footer className={styling.footer}>
      <img src={logo} alt="logo" />

      <div className={styling.footer__links}>
        <ul>
          <li>
            <Link to="/">Offers</Link>
          </li>
          <li>
            <Link to="#" onClick={handleContactUsClick}>Contact Us</Link>
          </li>
          <li>
            <Link to="/">Book Now</Link>
          </li>
        </ul>
      </div>

      <div className={styling.app__footer_links_icons}>
        <FiFacebook />
        <FiTwitter />
        <FiInstagram />
      </div>
      <div className={styling.footer__copyright}>
        <p>
          &copy; 2024 Hotelsonclik. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
