import axios from "axios";

const API_BASE_URL = "https://hoc-backend.onrender.com/api";

const apiService = axios.create({
  baseURL: API_BASE_URL,
});

const AuthService = {
  submitLead: async (lead) => {
    try {
      const response = await apiService.post("/forms/submitlform", lead);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  submitContact: async (contact) => {
    try {
      const response = await apiService.post("/forms/contact", contact);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default AuthService;
