import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Logo from "../../assets/hotelsonclik-logos/Hotelsonclik-logos_white.png";
import styles from "./bookNowModal.module.css";
import hotels from "../../constants/data";
import AuthService from "../../services/AuthService";
import dayjs from "dayjs";

// import { isAfter, startOfDay } from "date-fns";
import "react-phone-input-2/lib/bootstrap.css";
import { BsStackOverflow } from "react-icons/bs";
const darkTheme = createTheme({
  palette: {
    mode: "dark", // Set the theme mode to dark
  },
});

const Form = ({ closeFn }) => {
  const roomOptions = [1, 2, 3, 4, 5];
  const adultOptions = [1, 2, 3, 4, 5, 6, "+6"];
  const childrenOptions = [0, 1, 2, 3, 4];
  const hotelOptions = hotels.map((hotel) => hotel.name);
  const [formData, setFormData] = useState({
    hotel: "",
    checkInDate: null,
    checkOutDate: null,
    adults: "",
    children: "",
    rooms: "",
    contactNumber: "",
    email: "",
  });

  const handleCheckInDateChange = (date) => {
    setFormData({ ...formData, checkInDate: date });
  };

  const handleCheckOutDateChange = (date) => {
    setFormData({ ...formData, checkOutDate: date });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await AuthService.submitLead(formData);
      console.log("Booking submitted successfully");
      alert(
        "Your booking request has been submitted successfully! Our executive will get in touch with you soon."
      );
      setFormData({
        hotel: "",
        checkInDate: null,
        checkOutDate: null,
        adults: "",
        children: "",
        rooms: "",
        contactNumber: "",
        email: "",
      });
      closeFn();
    } catch (error) {
      console.error("Error submitting booking:", error.message);
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <form className={styles.bookingForm} onSubmit={handleSubmit}>
        <div className={styles.closeBtn} onClick={closeFn}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="none"
            stroke="var(--color-golden-2)"
            strokeWidth="1"
            className="bi bi-x"
            viewBox="0 0 16 16"
          >
            <path d="M3 3l10 10M3 13l10-10" />
          </svg>
        </div>
        {/* Logo */}
        <div className={styles.logo}>
          <img src={Logo} alt="Hotelsonclik Logo" />
        </div>
        {/* Hotel Selection Dropdown */}
        <TextField
          select
          label="Hotel Selection"
          fullWidth
          margin="normal"
          id="standard-basic"
          variant="standard"
          value={formData.hotel}
          onChange={(e) => setFormData({ ...formData, hotel: e.target.value })}
        >
          {hotelOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>

        {/* Check-in and Check-out Dates */}
        <Stack direction="row" spacing={1} marginTop={"1rem"}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {/* <DemoContainer
            components={["DatePicker"]}
            direction="row" 
            spacing={0}
          > */}

            <DatePicker
              label="Check-in Date"
              value={formData.checkInDate}
              minDate={dayjs().startOf("day")}
              onChange={handleCheckInDateChange}
              slotProps={{
                textField: {
                  id: "standard-basic",
                  variant: "standard",
                  sx: {
                    "& .MuiButtonBase-root": {
                      borderRadius: "0px",
                      right: "0.75rem",
                      bottom: "0.25rem",
                    },
                  },
                },
              }}
            />
            <DatePicker
              label="Check-out Date"
              value={formData.checkOutDate}
              onChange={handleCheckOutDateChange}
              minDate={formData.checkInDate}
              slotProps={{
                textField: {
                  id: "standard-basic",
                  variant: "standard",
                  sx: {
                    "& .MuiFormControl-root": {
                      marginLeft: "10px",
                    },
                    "& .MuiButtonBase-root": {
                      borderRadius: "0px",
                      right: "0.75rem",
                      bottom: "0.25rem",
                    },
                  },
                },
              }}
            />

            {/* </DemoContainer> */}
          </LocalizationProvider>
        </Stack>

        {/* Number of Rooms, Adults, and Children */}
        <Stack direction="row" spacing={1} marginTop={"1rem"}>
          <TextField
            select
            label="Adults"
            id="standard-basic"
            variant="standard"
            margin="normal"
            fullWidth
            value={formData.adults}
            onChange={(e) =>
              setFormData({ ...formData, adults: e.target.value })
            }
          >
            {adultOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Children"
            id="standard-basic"
            variant="standard"
            margin="normal"
            value={formData.children}
            fullWidth
            onChange={(e) =>
              setFormData({ ...formData, children: e.target.value })
            }
          >
            {childrenOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Stack>

        <Stack direction="row" spacing={1} marginTop={"1rem"}>
          <TextField
            select
            label="Rooms"
            id="standard-basic"
            variant="standard"
            margin="normal"
            value={formData.rooms}
            fullWidth
            onChange={(e) =>
              setFormData({ ...formData, rooms: e.target.value })
            }
          >
            {roomOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        <Stack direction="row" spacing={1} marginTop={"1rem"}>
          <TextField
            label="Contact Number"
            id="standard-basic"
            variant="standard"
            value={formData.contactNumber}
            margin="normal"
            type="tel"
            inputProps={{ maxLength: 10, minLength: 10, pattern: "[0-9]{10}" }}
            fullWidth
            onChange={(e) =>
              setFormData({ ...formData, contactNumber: e.target.value })
            }
          />
        </Stack>

        {/* Email */}
        <TextField
          label="Email"
          margin="normal"
          fullWidth
          id="standard-basic"
          variant="standard"
          value={formData.email}
          type="email"
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
        />

        {/* Book Now Button */}
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          type="submit"
          sx={{
            borderRadius: "0px",
            borderColor: "var(--color-golden-2)",
            color: "var(--color-golden-2)",
            marginTop: "1rem",
            "&:hover": {
              borderColor: "var(--color-golden)",
              color: "var(--color-golden)",
            },
          }}
        >
          Book Now
        </Button>

        {/* Or Call */}
        <div className={styles.callText}>
          Or Call -
          <a href="tel:+919818188768" alt="Contact">
            {" "}
            +919818188768{" "}
          </a>
          for Booking.
        </div>
      </form>
    </ThemeProvider>
  );
};

export default Form;
