import React from "react";
import {
  BsInstagram,
  BsArrowLeftShort,
  BsArrowRightShort,
} from "react-icons/bs";

import "./gallery.css";

const Gallery = () => {
  const scrollRef = React.useRef(null);

  const img = [
    "https://pix8.agoda.net/hotelImages/411055/-1/727ff49f5b957c96ecf70cfb5421e380.jpg?ce=0&s=1024x768",
    "https://res.cloudinary.com/dqsmrlhbk/image/upload/v1710573950/HotelsOnClik/Hotels/TreesNTigersSariska/WhatsApp_Image_2024-01-21_at_15.16.59_nuet7i.jpg",
    "https://pix8.agoda.net/hotelImages/107/10706850/10706850_21010218170093958483.jpg?ca=16&ce=1&s=1024x768",
    "https://lh3.googleusercontent.com/p/AF1QipOJcU6NB1y3W7-yauKZu3lwUrVL7kzUjo9ff6UU=s1360-w1360-h1020",
    "https://images.unsplash.com/photo-1593693397690-362cb9666fc2?q=80&w=2938&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    "https://pix8.agoda.net/hotelImages/1143724/-1/ea52987663c8592f04ed87e49e164fc4.jpg?ca=6&ce=1&s=1024x768",
    "https://pix8.agoda.net/hotelImages/411055/-1/727ff49f5b957c96ecf70cfb5421e380.jpg?ce=0&s=1024x768",
    "https://cdn1.goibibo.com/voy_mmt/t_fs/htl-imgs/200701131408174293-561589bc2f5711ee98f30a58a9feac02.jpg",
    "https://cdn1.goibibo.com/voy_mmt/t_fs/htl-imgs/200701131408174293-ccd1a8ee2eba11eeb6fb0a58a9feac02.jpg",
    "https://images.unsplash.com/photo-1593693397690-362cb9666fc2?q=80&w=2938&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  ];

  const scroll = (direction) => {
    const { current } = scrollRef;

    if (direction === "left") {
      current.scrollLeft -= 300;
    } else {
      current.scrollLeft += 300;
    }
  };

  return (
    <div className="app__gallery flex__center">
      <div className="app__gallery-content">
        <h1 className="app__gallery-heading">Photo Gallery</h1>
        <p className="p__opensans" style={{ marginTop: "1rem" }}>
          At HotelsOnClik, explore our premier site for booking hotels and
          resorts. Discover exclusive travel packages while browsing through our
          captivating photo gallery showcasing stunning accommodations
          worldwide. Enjoy detailed descriptions, genuine reviews, and
          competitive prices for your perfect stay.
        </p>
        <button type="button" className="custom__button">
          View More
        </button>
      </div>

      <div className="app__gallery-images">
        <div className="app__gallery-images_container" ref={scrollRef}>
          {img.map((image, index) => (
            <div
              className="app__gallery-images_card flex__center"
              key={`gallery_image-${index + 1}`}
            >
              <img src={image} alt="gallery" />
              <BsInstagram className="gallery__image-icon" />
            </div>
          ))}
        </div>
        <div className="app__gallery-images_arrow">
          <BsArrowLeftShort
            className="app__arrow-icon"
            onClick={() => scroll("left")}
          />
          <BsArrowRightShort
            className="app__arrow-icon"
            onClick={() => scroll("right")}
          />
        </div>
      </div>
    </div>
  );
};

export default Gallery;
