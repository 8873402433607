import Navbar from './container/Navbar/navbar';
import Home from './pages/Home/home';
import Hotel from './pages/Hotels/hotels';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import Data from "./constants/data";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          {Data.map((hotel) => (
            <Route path={`/hotels/${hotel.link}`} element={<Hotel />} />
          ))}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
