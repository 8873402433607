const Hotels = [
  {
    id: 1,
    name: "Tiger Camp Resort, Jim Corbett",
    link: "TigerCampResort",
    images:"https://cdn1.goibibo.com/voy_mmt/t_fs/htl-imgs/200701131408174293-ccd1a8ee2eba11eeb6fb0a58a9feac02.jpg"
  },
  {
    id: 2,
    name: "Trees N Tigers, Sariska",
    link: "TreesNTigersSariska",
    images:"https://pix8.agoda.net/hotelImages/411055/-1/727ff49f5b957c96ecf70cfb5421e380.jpg?ce=0&s=1024x768"
  },
  {
    id: 3,
    name: "Trees N Tigers, Tadoba",
    link: "TreesNTigersTadoba",
    images:"https://res.cloudinary.com/dqsmrlhbk/image/upload/v1710573950/HotelsOnClik/Hotels/TreesNTigersSariska/WhatsApp_Image_2024-01-21_at_15.16.59_nuet7i.jpg"
  },
  {
    id: 4,
    name: "Charekh Food and Forest Resort, Lansdowne",
    link: "CharekhFoodandForestResort",
    images:"https://pix8.agoda.net/hotelImages/107/10706850/10706850_21010218170093958483.jpg?ca=16&ce=1&s=1024x768"
  },
  {
    id: 5,
    name: "The Dream Forest Resort, Eklaidi",
    link: "TheDreamForestResort",
    images:"https://lh3.googleusercontent.com/p/AF1QipOJcU6NB1y3W7-yauKZu3lwUrVL7kzUjo9ff6UU=s1360-w1360-h1020"
  },
  // {
  //   id: 6,
  //   name: "Hotel Apra Deluxe, Karol Bagh",
  //   link: "HotelApraDeluxe",
  // },
  {
    id: 7,
    name: "Hotel Naldehra, Shimla",
    link: "HotelNaldehra",
    images:"https://res.cloudinary.com/dqsmrlhbk/image/upload/v1710573954/HotelsOnClik/Hotels/TreesNTigersSariska/WhatsApp_Image_2024-01-21_at_15.17.00_sofjjz.jpg"
  },
];

export default Hotels;