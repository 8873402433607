import React from "react";
import "../Home/home.css";
import Carousel from "../../components/Carousel/carousel";
import Footer from "../../container/Footer/footer";

const Hotel = () => (
  <div>
  <div className="Home_page">
    <div className="Hero_Section">
      <Carousel />
    </div>
    

    <Footer />
  </div>
  </div>
);

export default Hotel;