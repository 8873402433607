import React, { useState } from "react";
import classes from "./card1.module.css";


const Card1 = ({ cardImg, cardTitle, cardContent, isBookNowClicked}) => {

  const handleBookClick = () => {
    console.log("Card1 handleBookClick");
    isBookNowClicked(true);
  };

  const cardImage = `url(${cardImg})`;
  return (
    <div className={classes["card-wrap"]}>
      <div
        className={`${classes["card-header"]} ${classes["one"]}`}
        style={{ backgroundImage: cardImage }}
      ></div>
      <div className={classes["card-content"]}>
        <p className={classes["card-title"]}>{cardTitle}</p>
        {/* <p className={classes["card-text"]}>{cardContent}</p> */}
        <button className={`${classes["card-btn"]} ${classes["one"]}`} onClick={()=>handleBookClick()}>
          Book Now
        </button>
      
      </div>
    </div>
  );
};

export default Card1;
