import React, { useState } from "react";
import styles from "./contactus.module.css";
import { IoMdMail } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import AuthService from "../../services/AuthService"; // Make sure the path is correct

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await AuthService.submitContact(formData);
      setSuccess("Form submitted successfully");
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
      });
      setError(null);
    } catch (err) {
      setError("Error submitting the form. Please try again.");
      console.error("Error submitting the form:", err.message);
      setSuccess(null);
    }
  };

  return (
    <section id="cnt" className={styles.footerGetTouchOuter}>
      <div className={styles.container}>
        <div className={styles.dispFlex}>
          <div className={styles.getFormInner}>
            <div className={styles.getFormInnerText}>
              <h3>Get In Touch</h3>
            </div>
            <form onSubmit={handleSubmit}>
              <div className={styles.grid5050}>
                <input
                  type="text"
                  name="firstName"
                  className={styles.contactUsInput}
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleChange}
                />
                <input
                  type="text"
                  name="lastName"
                  className={styles.contactUsInput}
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleChange}
                />
                <input
                  type="email"
                  name="email"
                  className={styles.contactUsInput}
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <input
                  type="tel"
                  name="phone"
                  className={styles.contactUsInput}
                  placeholder="Phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
              <div className={styles.gridFull}>
                <textarea
                  name="message"
                  placeholder="Write your message here."
                  cols="30"
                  rows="10"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
                <input className={styles.input} type="submit" value="Submit" />
              </div>
              {error && <p className={styles.error}>{error}</p>}
              {success && <p className={styles.success}>{success}</p>}
            </form>
          </div>
          <div className={styles.getSayForm}>
            <h5>Say Hi!</h5>
            <ul className={styles.getSayInfoSec}>
              <a href="mailto:sales@hotelsonclik.com">
                <li className={styles.getSayInfoLinks}>
                  <i className="fa fa-envelope">
                    <IoMdMail />
                  </i>
                  <div className={styles.getSayInfoLink}>
                    sales@hotelsonclik.com
                  </div>
                </li>
              </a>
              <a href="tel:+919818188768">
                <li className={styles.getSayInfoLinks}>
                  <i className="fa fa-whatsapp">
                    <FaWhatsapp />
                  </i>
                  <div className={styles.getSayInfoLink}>+91 98181 88768</div>
                </li>
              </a>
              <a href="">
                <li className={styles.getSayInfoLinks}>
                  <i className="fa fa-address">
                    <FaLocationDot />
                  </i>
                  <div className={styles.getSayInfoLink}>
                    D-30 2nd Floor, West Patel Nagar. New Delhi-110008
                  </div>
                </li>
              </a>
            </ul>
            <ul className={styles.getSaySocialIcn}>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
